.cost-page-content {
  height: 100vh;
  background-color: #ffffff;
  padding: 24px 0;
}

.card_detail-name {
  display: flex;
  flex-direction: column;
}

.cost-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

.cost-row {
  display: flex;
  gap: 25px;
}

.cost-container {
  margin: auto;
  width: 876px;
  max-width: 100%;
}

.cost-container form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cost-container input,
.cost-container div,
.cost-container form {
  width: 100%;
}

.cost-btn {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
}

div.cost-btn > .search-by-btn {
  justify-self: self-start;
}

.cost-btn > .button {
  position: absolute;
  grid-column: 2;
  justify-self: center;
}

.cost-btn > .dropdown {
  grid-column: 3;
  justify-self: end;
  width: fit-content;
}
.result-container-cost {
  display: flex;
}

.cost-results__container-tag {
  padding: 4px 16px;
  background-color: #dbe1f1;
  border-radius: 20px;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #080707;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 3px;
}

.cost-card-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  position: relative;
}

.cost-card-result {
  background-color: transparent;
  box-shadow: none;
  color: #000;
}

.result-container-cost {
  display: flex;
  width: 100%;
}

.cost-card-result {
  padding: 30px 30px 25px 40px;
  border-bottom: 0.8px solid rgb(101, 71, 71);
  background-color: white;
  position: relative;
}

.page-result {
  padding: 0px 90px 0px 90px;
  background-color: #f1f1f1;
}

.results-bar {
  display: flex;
  background-color: #f1f1f1;
  margin-left: 90px;
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 33px;
  font-size: 22px;
  font-family: "Poppins";
  height: 3vh;
}
.npi-error {
  color: #d94411;
  font-weight: 400px;
  margin-top: 8px;
}

.error-message-return {
  display: flex;
  max-width: 70%;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: black;
  font-family: "Crimson Pro", serif;
  font-weight: 200px;
}

.results-module-section {
  background-color: #f1f1f1;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  margin-top: 23px;
}

.total-cost-tag {
  display: flex;
  font-weight: 600px;
  font-size: 24px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
}

.card-secondary-info {
  color: #262525;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  max-width: 80%;
}

.cost-results__container-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 72px;
}

.estimate-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  flex-direction: column;
}

.estimate-box {
  display: flex;
  background-color: #F2F1EF;
  padding: 32px;
  gap: 32px;
  flex-direction: column;
  margin-bottom: 32px;
  }


  .provider-description-section {
    display: flex;
    width: 754px;
    height: 65px;

  }

  .estimate-details-title {
    display:flex;
    width: 344px;
    height: 32px;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.15px;
    font-style: "Poppins";
  }

  .doctor-description {
    width: 387px;
    height: 32px;
    top: 35px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.13px;
    max-width: 90%;
  }
  .estimates-titles-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-style: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  }

.fee-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 10%;
  font-style: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}


hr.solid {
  border-top: 3px solid #bbb;
}

.back-button-estimateDetails {
  width: 196px;
  height: 24px;
}

.header-estimate-details {
  display: flex;
  width: 890px;
  height: 113px;
gap: 16px;
}

.facility-info-estimate-details {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins";
  width: 756px;
}

.provider-specialty {
  width: 325px;
  height: 48px;
  max-width: 320px;
}

.facility-description-section {
  display: flex;
  width: 754px;
  height: 80px;

}

.facility-specialty {
  width: 360px;
  height: 32px;
  max-width: 320px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.13px;

}

.not-available-cost-tag {
  max-width: 2150px;
  height: 30px;
  font-weight: 600px;
  font-size: 18px;
  margin-bottom: 0;
}